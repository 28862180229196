html {
    font-size: 62.5%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

button:disabled {
    opacity: 0.5;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

/* Effectively disable Chrome's auto-styling of autofill input fields */
/* https://stackoverflow.com/a/59572885/6147743 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-transition-delay: 99999s;
}

.screen-reader-only {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    overflow: hidden !important;
 }

 /* STATE FONT STYLES */
 .stateface:before {
    font-family: StateFaceRegular;
}

.stateface-replace {
    text-indent: -999em;
    display: inline-block;
    position: relative;
    min-width: 1em;
}

.stateface-replace:before {
    position: absolute;
    left: 0;
    top: 0;
    text-indent: 0;
}

.stateface-ak:before {
    content: "A";
}

.stateface-al:before {
    content: "B";
}

.stateface-ar:before {
    content: "C";
}

.stateface-az:before {
    content: "D";
}

.stateface-ca:before {
    content: "E";
}

.stateface-co:before {
    content: "F";
}

.stateface-ct:before {
    content: "G";
}

.stateface-dc:before {
    content: "y";
}

.stateface-de:before {
    content: "H";
}

.stateface-fl:before {
    content: "I";
}

.stateface-ga:before {
    content: "J";
}

.stateface-hi:before {
    content: "K";
}

.stateface-ia:before {
    content: "L";
}

.stateface-id:before {
    content: "M";
}

.stateface-il:before {
    content: "N";
}

.stateface-in:before {
    content: "O";
}

.stateface-ks:before {
    content: "P";
}

.stateface-ky:before {
    content: "Q";
}

.stateface-la:before {
    content: "R";
}

.stateface-ma:before {
    content: "S";
}

.stateface-md:before {
    content: "T";
}

.stateface-me:before {
    content: "U";
}

.stateface-mi:before {
    content: "V";
}

.stateface-mn:before {
    content: "W";
}

.stateface-mo:before {
    content: "X";
}

.stateface-ms:before {
    content: "Y";
}

.stateface-mt:before {
    content: "Z";
}

.stateface-nc:before {
    content: "a";
}

.stateface-nd:before {
    content: "b";
}

.stateface-ne:before {
    content: "c";
}

.stateface-nh:before {
    content: "d";
}

.stateface-nj:before {
    content: "e";
}

.stateface-nm:before {
    content: "f";
}

.stateface-nv:before {
    content: "g";
}

.stateface-ny:before {
    content: "h";
}

.stateface-oh:before {
    content: "i";
}

.stateface-ok:before {
    content: "j";
}

.stateface-or:before {
    content: "k";
}

.stateface-pa:before {
    content: "l";
}

.stateface-ri:before {
    content: "m";
}

.stateface-sc:before {
    content: "n";
}

.stateface-sd:before {
    content: "o";
}

.stateface-tn:before {
    content: "p";
}

.stateface-tx:before {
    content: "q";
}

.stateface-us:before {
    content: "z";
}

.stateface-ut:before {
    content: "r";
}

.stateface-va:before {
    content: "s";
}

.stateface-vt:before {
    content: "t";
}

.stateface-wa:before {
    content: "u";
}

.stateface-wi:before {
    content: "v";
}

.stateface-wv:before {
    content: "w";
}

.stateface-wy:before {
    content: "x";
}
